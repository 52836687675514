.ctaButton {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  min-width: 64px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin: 10px 0 5px 0;
  padding: 6px 16px;
  border: 0;
  outline: 0;
  cursor: pointer;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  background: var(--goldGradient);
  font-family: var(--mainText);
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.75;
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  -moz-letter-spacing: 0.08em;
  -ms-letter-spacing: 0.08em;
  text-transform: uppercase;
}

.ctaButton:disabled {
  cursor: default;
  pointer-events: none;
  box-shadow: none;
  font-family: var(--mainText);
  color: rgba(60, 60, 60, 0.87);
  background: rgb(42, 52, 62, 0.5);
}
