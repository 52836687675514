.footer {
  display: flex;
  color: white;
  padding: 0;
  padding-bottom: 20px;
  width: 100%;
  max-width: 444px;
}

.footer-icons {
  display: flex;
  flex-direction: row; /*main right to left*/
  justify-content: space-evenly; /*main axis*/
  align-items: center; /*cross axis*/
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

a svg {
  fill: #ffffff;
}

.footer-icons svg:hover {
  fill: #ffffff;
  cursor: pointer;
}
