@import url("https://use.typekit.net/xfu4soz.css");

@font-face {
  font-family: "URWClassicoRegular";
  src: local("URWClassicoRegular"),
    url("./fonts/URWClassico-Regular.ttf") format("truetype");
}

/* @font-face {
  font-family: "URWClassicoRegular";
  src: local("URWClassico"),
    url("./fonts/URWClassico-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "URWClassicoBold";
  src: local("URWClassico"),
    url("./fonts/URWClassico-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "URWClassicoBoldItalic";
  src: local("URWClassico"),
    url("./fonts/URWClassico-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "URWClassicoItalic";
  src: local("URWClassico"),
    url("./fonts/URWClassico-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

.local {
  font-family: "URWClassico", sans-serif;
} */

:root {
  --mainBg: #000000;
  --secondaryBg: rgb(21, 26, 31);
  --goldGradient: linear-gradient(180deg, #fff7cc 0%, #ffd699 100%);
  --darkGradient: linear-gradient(
    180deg,
    #323232 0%,
    #212121 75%,
    #101010 100%
  );
  --mainText: "Rosella Engraved", "rosella-engraved", serif;
  --secondaryText: "URWClassicoRegular", sans-serif;
}

html,
body {
  background: url("assets/zilly-7777_bg-pattern.png");
  background-image: url("assets/zilly-7777_bg-pattern.svg");
  background-repeat: repeat;
  background-color: #000000;
  background-size: 251px 250px;
  margin: 0;
  padding: 0;
  font-family: var(--mainText);
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.03em;
  text-transform: none;
}

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

.Button {
  color: black;
}
.Button:disabled {
  color: gray;
  background: gray;
}
