.Home-Outer-Container {
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-right: 24px;
  padding-bottom: 40px;
  padding-left: 24px;
}

.Home-Inner-Container {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0px;
  padding-right: 0px;
  /* padding-left: 16px;
  padding-right: 16px; */
}

.Home-Paper {
  display: block;
  color: #fff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 20px;
  background-color: rgb(21, 26, 31);
  border-radius: 6px;
}

.Home-Grid-Outer {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  box-sizing: border-box;
}

.Home-Grid-Item-1 {
  display: block;
  margin: 0;
  box-sizing: border-box;
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
  margin-right: 30px;
}

.Home-Grid-Item-2 {
  display: block;
  margin: 0;
  box-sizing: border-box;
  flex-grow: 0;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
}

.Home-Grid-Item-3 {
  display: block;
  margin: 0;
  box-sizing: border-box;
  flex-grow: 0;
  max-width: 41.666667%;
  flex-basis: 41.666667%;
}

.Home-MintContainer {
  display: block;
}

button.Connect-Button {
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: var(--goldGradient);
  color: black;
  font-size: 16px;
  font-weight: bold;
  font-family: var(--mainText);
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  cursor: pointer;
}

button.crossmintButton {
  width: 100%;
  height: 40px;
  box-shadow: none;
  display: inline-flex;
  align-items: center;
  justify-content: left;
  margin-top: 5px;
  margin-bottom: 0px;
  vertical-align: middle;
  background: var(--secondaryBg);
  color: white;
  font-size: 14px;
  font-weight: bold;
  font-family: var(--secondaryText);
  font-weight: 400;
  font-style: normal;
  line-height: 1.75;
  text-decoration: none;
  letter-spacing: 0.08em;
  -moz-letter-spacing: 0.08em;
  -ms-letter-spacing: 0.08em;
  text-transform: uppercase;
  cursor: pointer;
}

button.Connect-Button:disabled {
  cursor: default;
  pointer-events: none;
  box-shadow: none;
  font-family: var(--mainText);
  color: rgba(60, 60, 60, 0.87);
  background: rgb(42, 52, 62, 0.5);
}

/* ===============
 * Media Queries
 * ===============
 */

@media (min-width: 0px) {
  .Home-Inner-Container {
    max-width: 444px;
  }
}

@media (min-width: 600px) {
  .Home-Outer-Container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .Home-Inner-Container {
    /* padding-left: 24px;
    padding-right: 24px; */
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 1280px) {
  .Home-Outer-Container {
    max-width: 1280px;
  }
}
