.hero {
  display: flex;
  padding: 20px 0 0 0;
  margin: 0 auto;
  width: 100%;
  max-width: 444px;
}

.heroImage {
  width: 100%;
  height: auto;
  max-width: 444px;
}

.svg {
  width: 100%;
  max-width: 444px;
}

/* ===============
 * Media Queries
 * ===============
 */

@media (max-width: 580px) {
  .hero,
  .svg {
    max-width: 444px;
  }
}

@media (max-width: 480px) {
  .hero,
  .svg {
    max-width: 444px;
  }
}

@media (max-width: 320px) {
  .hero,
  .svg {
    max-width: 444px;
  }
}
